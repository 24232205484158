import React from 'react';
import './styles/App.scss';

function App() {
  return (
    <div className="App flex flex-wrap" style={{ }}>
      <div className="App__info vh100 col-9 md:col-3 p1 md:p2 pr2 md:pr3 relative">
        <h1 className="headline site-title mb8">Sandworks, Inc.</h1>
        <span className="title color-red block my2">
          Technology consulting for the web. <br /> Engineering shop. Ecommerce optimization.
        </span>
        <div className="absolute mb1 md:mb2 ml1 md:ml2 b0 l0">
          <span className="small-headline mr2">Coming in 2021</span>
          <span className="small-headline">
            <a href="mailto:joshie@sand.works" className="pb_25">Get in Touch</a>
          </span>
        </div>
        {/* <span className="title color-red block mb2">
          Your business requires technology with sails.
        </span> */}
        {/* 
        <span className="subtitle block">
          Implementing proven user experience practices, deploying award-winning interactive applications, and optimizing for key performance metrics for over a decade. Now taking on new clients.
        </span> */}
        {/* <div className="flex col-12 mt3">
          <span className="headline mr2">Coming in <span className="none md:inline">the Good Year of</span> 2021</span>
          <span className="headline">
            <a href="mailto:joshie@sand.works" className="pb_25">Get in Touch</a>
          </span>
        </div>
      </div>
      <div className="vh100 none col-12 md:col-6 md:block p1 md:p2 pr2 ">
        <div
          className="App__image vh100 col-12 md:col-6 none md:block"
          style={{
            background: 'url("/assets/images/ca-t35-1.jpg") no-repeat center',
            backgroundSize: 'cover'
          }}
        /> */}
      </div>
      <div className="col-3 md:col-9 p1 md:p2">
        <div
          className="h100 w100"
          style={{
            background: 'url("/assets/images/ca-t35-1.jpg") repeat-x'
          }}
        >
        </div>
        
      </div>
    </div>
  );
}

export default App;
